.createButton {
    margin: 16px !important;
}

.category {
    font-weight: bold;
    padding: 16px 0;
}

.activeProduct {
    background: rgba(0 0 0 .05) !important;
}

.expanded {
    /* padding: theme.spacing(1 2) */
}

.expandedActions {
    display: flex;
    justify-content: flex-end;
}

.qtyInput {
    margin-left: auto;
    width: 80px;
}

.tabList {
    background-color: #eee;
    margin: 5px;
}

.tab {
    border: 0;
    padding: 16px;
    font-size: 1em;
}

.activeTab, .tab:hover {
    background-color: #616161;
    color: #fff;
}